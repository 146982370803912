.CandidateTab__container {
  padding: 8px;
}
dl {
  margin-bottom: 10px;
}

dl dt {
  float: left;
}

dl dd {
  margin-bottom: 8px;
  text-align: right;
}

dl dd .rating {
  float: right;
  position: relative;
  top: -5px;
}
