.planningInformation__headline {
  font-size: 16px;
  font-weight: bold;
}

.planningInformaiton__radio {
  font-size: 14px;
}

.planningInformation__wizardButton {
  padding: 12px 24px;
}

.planningInformation__headingSection {
  padding-top: 14px;
}

.planningInformation__spacer {
  height: 26px;
  width: 100%;
  clear: both;
}